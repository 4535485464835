/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useHistory, useParams } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import AlertsPageTableRowActions from './AlertsPageTableRowActions'
import useIgnoreAlertMutation from '../../utils/useIgnoreAlertMutation'
import useRestoreAlertMutation from '../../utils/useRestoreAlertMutation'
import useReviewAlertMutation from '../../utils/useReviewAlertMutation'
import CreateOrganizationCookieModalWithState from '../classifications-organization-cookies/CreateOrganizationCookieModalWithState'
import CreateOrganizationTrackerModalWithState from '../classifications-organization-trackers/CreateOrganizationTrackerModalWithState'
import UpdateOrganizationTrackerModalWithState from '../classifications-organization-trackers/UpdateOrganizationTrackerModalWithState'
import UpdateOrganizationCookieModalWithState from '../classifications-organization-cookies/UpdateOrganizationCookieModalWithState'
import DeleteOrganizationCookieModalWithState from '../classifications-organization-cookies/DeleteOrganizationCookieModalWithState'
import DeleteOrganizationTrackerModalWithState from '../classifications-organization-trackers/DeleteOrganizationTrackerModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AlertsPageTableRowActionsWithState = ({
    level,
    alert,
    selectedPeriod,
    onAlertStatusChanged,
}) => {
    const history = useHistory()
    const { id: auditId } = useParams()
    const { currentUser } = useAppState()
    const [
        updateOrganizationTrackerModalIsOpen,
        setUpdateOrganizationTrackerModalIsOpen,
    ] = useState(false)
    const [
        deleteOrganizationTrackerModalIsOpen,
        setDeleteOrganizationTrackerModalIsOpen,
    ] = useState(false)
    const [
        updateOrganizationCookieModalIsOpen,
        setUpdateOrganizationCookieModalIsOpen,
    ] = useState(false)
    const [
        deleteOrganizationCookieModalIsOpen,
        setDeleteOrganizationCookieModalIsOpen,
    ] = useState(false)
    const [ignoreAlert] = useIgnoreAlertMutation({
        onCompleted: onAlertStatusChanged,
    })
    const [reviewAlert] = useReviewAlertMutation({
        onCompleted: onAlertStatusChanged,
    })
    const [restoreAlert] = useRestoreAlertMutation({
        onCompleted: onAlertStatusChanged,
    })
    let isIgnored = false
    let isReviewed = false
    if (level === 'ORGANIZATION') {
        isIgnored = alert.status === 'IGNORED'
        isReviewed = alert.status === 'REVIEWED'
    }
    if (level === 'AUDIT') {
        isIgnored = alert.auditStatus === 'IGNORED'
        isReviewed = alert.auditStatus === 'REVIEWED'
    }
    const { dispatchSuccess, dispatchError } = useNotifications()
    const variables = { groupingIds: [alert.id], level }
    if (level === 'AUDIT') {
        variables.auditId = alert.audit.id
    }

    let isWarning = false
    if (alert.severityType === 'WARNING') {
        isWarning = true
    }

    const { lastScanCookies, lastScanRequests, tracker, cookie } = alert
    const [scanCookie] = lastScanCookies
    const [scanTracker] = lastScanRequests
    return (
        <>
            {deleteOrganizationTrackerModalIsOpen && (
                <DeleteOrganizationTrackerModalWithState
                    title={<Trans>Remove your classification</Trans>}
                    tracker={tracker}
                    onDismiss={() =>
                        setDeleteOrganizationTrackerModalIsOpen(false)
                    }
                    // onCompleted={onDeleteTrackerCompleted}
                />
            )}
            {updateOrganizationTrackerModalIsOpen && (
                <>
                    {tracker.belongsToOrganization ? (
                        <UpdateOrganizationTrackerModalWithState
                            title={
                                <Trans>
                                    Update your tracker classification
                                </Trans>
                            }
                            tracker={tracker}
                            onDismiss={() =>
                                setUpdateOrganizationTrackerModalIsOpen(false)
                            }
                            // onCompleted={onUpdateTrackerCompleted}
                        />
                    ) : (
                        <CreateOrganizationTrackerModalWithState
                            title={<Trans>Change tracker classification</Trans>}
                            tracker={tracker}
                            scanTracker={scanTracker}
                            onDismiss={() =>
                                setUpdateOrganizationTrackerModalIsOpen(false)
                            }
                            // onCompleted={onClassifyTrackerCompleted}
                        />
                    )}
                </>
            )}
            {deleteOrganizationCookieModalIsOpen && (
                <DeleteOrganizationCookieModalWithState
                    title={<Trans>Remove your cookie classification</Trans>}
                    cookie={cookie}
                    onDismiss={() =>
                        setDeleteOrganizationTrackerModalIsOpen(false)
                    }
                    // onCompleted={onDeleteCookieCompleted}
                />
            )}
            {updateOrganizationCookieModalIsOpen && (
                <>
                    {cookie.belongsToOrganization ? (
                        <UpdateOrganizationCookieModalWithState
                            title={
                                <Trans>Update your cookie classification</Trans>
                            }
                            cookie={cookie}
                            scanCookie={scanCookie}
                            onDismiss={() =>
                                setUpdateOrganizationTrackerModalIsOpen(false)
                            }
                            // onCompleted={onUpdateCookieCompleted}
                        />
                    ) : (
                        <CreateOrganizationCookieModalWithState
                            title={<Trans>Change cookie classification</Trans>}
                            cookie={cookie}
                            scanCookie={scanCookie}
                            onDismiss={() =>
                                setUpdateOrganizationTrackerModalIsOpen(false)
                            }
                            // onCompleted={onClassifyCookieCompleted}
                        />
                    )}
                </>
            )}
            <AlertsPageTableRowActions
                cookie={cookie}
                tracker={tracker}
                isLoading={false}
                isIgnored={isIgnored}
                isReviewed={isReviewed}
                isWarning={isWarning}
                canIgnoreAlert={currentUser.role === OWNER}
                canReviewAlert={currentUser.role === OWNER}
                canUpdateTracker={tracker !== null}
                canDeleteTracker={
                    tracker !== null && tracker.belongsToOrganization
                }
                canUpdateCookie={cookie !== null}
                canDeleteCookie={
                    cookie !== null && cookie.belongsToOrganization
                }
                onExportAlert={async () => {
                    const params = new URLSearchParams({
                        period: selectedPeriod,
                    })
                    if (level === 'ORGANIZATION') {
                        history.push(
                            `/report/alert-summary/${
                                alert.id
                            }?${params.toString()}`
                        )
                    }
                    if (level === 'AUDIT') {
                        history.push(
                            `/report/alert-summary/${auditId}/${
                                alert.id
                            }?${params.toString()}`
                        )
                    }
                }}
                onIgnoreAlert={async () => {
                    try {
                        let message = null
                        if (isIgnored) {
                            await restoreAlert({ variables })
                            message = <Trans>Alert status cleared</Trans>
                        } else {
                            await ignoreAlert({ variables })
                            message = <Trans>Alert ignored</Trans>
                        }
                        dispatchSuccess({ message })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0] || {}
                        dispatchError({ message })
                    }
                }}
                onReviewAlert={async () => {
                    try {
                        let message = null
                        if (isReviewed) {
                            await restoreAlert({ variables })
                            message = <Trans>Alert status cleared</Trans>
                        } else {
                            await reviewAlert({ variables })
                            message = <Trans>Alert reviewed</Trans>
                        }
                        dispatchSuccess({ message })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0] || {}
                        dispatchError({ message })
                    }
                }}
                onUpdateTracker={() =>
                    setUpdateOrganizationTrackerModalIsOpen(true)
                }
                onDeleteTracker={() =>
                    setDeleteOrganizationTrackerModalIsOpen(true)
                }
                onUpdateCookie={() =>
                    setUpdateOrganizationCookieModalIsOpen(true)
                }
                onDeleteCookie={() =>
                    setDeleteOrganizationCookieModalIsOpen(true)
                }
            />
        </>
    )
}

export default AlertsPageTableRowActionsWithState
