/* eslint-disable no-fallthrough */
import { useState } from 'react'
import { format, addDays, endOfDay, startOfDay, endOfMonth } from 'date-fns'
import { useQueryParam, StringParam, withDefault } from 'use-query-params'
import * as constants from '../../../constants'

const {
    DATE_FORMAT_GRAPHQL_DATE: FORMAT,
    IDENTIFIED_PERIODS,
    IDENTIFIED_PERIOD_LAST_DAY_OPTION: LAST_DAY,
    IDENTIFIED_PERIOD_LAST_7_DAYS_OPTION: LAST_7,
    IDENTIFIED_PERIOD_LAST_14_DAYS_OPTION: LAST_14,
    IDENTIFIED_PERIOD_LAST_30_DAYS_OPTION: LAST_30,
    IDENTIFIED_PERIOD_DAY_OPTION: DAY,
    IDENTIFIED_PERIOD_MONTH_OPTION: MONTH,
    IDENTIFIED_PERIOD_CUSTOM_OPTION: CUSTOM,
} = constants

const MODIFIERS = {
    [LAST_DAY]: 1,
    [LAST_7]: 7,
    [LAST_14]: 14,
    [LAST_30]: 30,
}

const useScanRangeSelect = (initialPeriod = LAST_DAY) => {
    const [selectedPeriod, setSelectedPeriod] = useQueryParam(
        'period',
        withDefault(StringParam, initialPeriod)
    )
    const [startDateParam, setStartDateParam] = useQueryParam(
        'startDate',
        withDefault(StringParam, null)
    )
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    let finalStartDate = null
    let finalEndDate = null
    const selectedModifier = MODIFIERS[selectedPeriod]
    let period = IDENTIFIED_PERIODS[selectedPeriod]
    switch (selectedPeriod) {
        case DAY:
            if (startDateParam) {
                const day = new Date(startDateParam)
                finalStartDate = format(startOfDay(day), FORMAT)
                finalEndDate = format(endOfDay(day), FORMAT)
                break
            }
        case CUSTOM:
            if (startDate && endDate) {
                finalStartDate = format(startOfDay(new Date(startDate)), FORMAT)
                finalEndDate = format(endOfDay(new Date(endDate)), FORMAT)
                break
            }
        case MONTH:
            if (startDateParam) {
                finalStartDate = format(
                    startOfDay(new Date(startDateParam)),
                    FORMAT
                )
                finalEndDate = format(
                    addDays(endOfMonth(new Date(startDateParam)), 1),
                    FORMAT
                )
                break
            }
        default:
            if (selectedModifier) {
                finalStartDate = format(
                    addDays(new Date(), -selectedModifier),
                    FORMAT
                )
                finalEndDate = format(new Date(), FORMAT)
            } else {
                period = null
            }
            break
    }

    return {
        period,
        selectedPeriod,
        startDate: finalStartDate,
        endDate: finalEndDate,
        onChangeDate: (value, start, end) => {
            setSelectedPeriod(value)
            setStartDate(start)
            setEndDate(end)
            if ((value === MONTH || value === DAY) && start) {
                setStartDateParam(start)
            } else {
                setStartDateParam(null)
            }
        },
    }
}

export default useScanRangeSelect
