import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import AlertSummaryDataCard from './AlertSummaryDataCard'
import AlertSummaryInfoCard from './AlertSummaryInfoCard'
import AlertSummarySection, {
    AlertSummarySectionTitle,
} from './AlertSummarySection'
import { toTitleCase } from '../../../utils/text'
import LoadingIndicator from '../../utils/LoadingIndicator'
import PurposesTags from '../../utils/PurposesTags'
import AuditAlertSummaryRequestDetails from './AuditAlertSummaryRequestDetails'
import AlertSummaryAuditTable from './AlertSummaryAuditTable'

export default function OrganizationTrackerAlertSummaryReportPage({
    alert,
    isFetching,
}) {
    const { lastScanRequest, lastAlertsForAudit } = alert

    const tracker = alert?.tracker || alert?.payload.matchingTracker

    if (isFetching) {
        return (
            <PageContainer className="mx-auto max-w-5xl p-8">
                <AuditSummaryHeader />
                <div className="flex h-screen w-full items-center justify-center">
                    <LoadingIndicator />
                </div>
            </PageContainer>
        )
    }

    return (
        <PageContainer className="mx-auto max-w-5xl p-8">
            <AuditSummaryHeader />

            {/* Summary */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Summary</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryInfoCard
                    iconName={alert?.subjectType?.toLowerCase()}
                    type={alert?.severityType?.toLowerCase()}
                    title={toTitleCase(
                        alert?.type?.toLowerCase().split('_').join(' ')
                    )}
                    text={alert?.message}
                />
            </AlertSummarySection>

            <AlertSummarySection className="grid grid-cols-2 gap-x-8 gap-y-12">
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Vendor</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={alert?.vendor?.name}
                        description="Vendor"
                        avatarSrc={alert?.vendor?.imageUrl}
                        avatarIconName="building-07"
                        text={
                            alert?.vendor?.description ||
                            'No description found for this vendor'
                        }
                    />
                </div>
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Subject</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={tracker.name}
                        description="Tracker"
                        avatarSrc={alert?.vendor?.imageUrl}
                        avatarIconName="building-07"
                        afterTitle={
                            <PurposesTags
                                showAll
                                purposes={tracker.purposes || []}
                            />
                        }
                        text={
                            tracker.description || (
                                <Trans>
                                    No description found for this cookie
                                </Trans>
                            )
                        }
                    />
                </div>
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Audits overview</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryAuditTable audits={alert?.audits} />
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Domain</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryDataCard
                    avatarSrc={alert.audits[0]?.imageUrl}
                    avatarIconName="building-07"
                    title={alert?.audits[0]?.name}
                    description={alert.audits[0]?.domains.map(
                        (domain) => domain?.url
                    )}
                />
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert initiator example</Trans>
                </AlertSummarySectionTitle>
                {lastScanRequest !== undefined ? (
                    <div className="flex flex-col gap-2">
                        <AuditAlertSummaryRequestDetails
                            scanRequest={lastScanRequest}
                        />
                    </div>
                ) : (
                    <Trans>No initiator example found</Trans>
                )}
            </AlertSummarySection>

            <div className="my-16 h-[0.1rem] w-full bg-gray-200" />

            {lastAlertsForAudit.map((auditAlert) => {
                const { audit, scanRequest } = auditAlert
                return (
                    <>
                        {/* Domain Section */}
                        <AlertSummarySection className="mt-0">
                            <AlertSummarySectionTitle>
                                <Trans>Domain</Trans>
                            </AlertSummarySectionTitle>
                            <AlertSummaryDataCard
                                avatarSrc={audit?.imageUrl}
                                avatarIconName="building-07"
                                title={audit?.name}
                                description={audit?.domains?.map(
                                    (domain) => domain?.url
                                )}
                            />
                        </AlertSummarySection>
                        {/* Cookie Instance Example */}
                        <AlertSummarySection>
                            <AlertSummarySectionTitle>
                                <Trans>Alert Initiator example</Trans>
                            </AlertSummarySectionTitle>
                            {scanRequest !== undefined ? (
                                <div className="flex flex-col gap-2">
                                    <AuditAlertSummaryRequestDetails
                                        scanRequest={scanRequest}
                                    />
                                </div>
                            ) : (
                                <Trans>No initiator example found</Trans>
                            )}
                        </AlertSummarySection>
                    </>
                )
            })}
        </PageContainer>
    )
}
