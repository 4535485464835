import React from 'react'
import { useParams, useLocation } from 'react-router-dom'
import AuditCookieAlertSummaryReportPage from './AuditCookieAlertSummaryReportPage'
import AuditTrackerAlertSummaryReportPage from './AuditTrackerAlertSummaryReportPage'
import useScanRangeSelect from '../alerts/useScanRangeSelect'
import useAuditAlertSummaryQuery from './useAuditAlertSummaryQuery'
import useOrganizationAlertSummaryQuery from './useOrganizationAlertSummaryQuery'
import OrganizationCookieAlertSummaryReportPage from './OrganizationCookieAlertSummaryReportPage'
import OrganizationTrackerAlertSummaryReportPage from './OrganizationTrackerAlertSummaryReportPage'

export default function AuditAlertSummaryReportPage() {
    const { id: auditId, groupingId } = useParams()
    const { search } = useLocation()
    const queryParams = new URLSearchParams(search)

    const period = queryParams.get('period') || 'LAST_DAY'

    const { startDate, endDate } = useScanRangeSelect(period)

    if (auditId) {
        const { alert, isFetching } = useAuditAlertSummaryQuery({
            groupingId,
            startDate,
            endDate,
            auditId,
        })
        return (
            <>
                {alert?.cookie && (
                    <AuditCookieAlertSummaryReportPage
                        alert={alert}
                        isFetching={isFetching}
                    />
                )}
                {alert?.tracker && (
                    <AuditTrackerAlertSummaryReportPage
                        alert={alert}
                        isFetching={isFetching}
                    />
                )}
            </>
        )
    }

    if (auditId === undefined) {
        const filters = {
            groupingId,
            startDate,
            endDate,
        }
        const { alert, isFetching } = useOrganizationAlertSummaryQuery({
            filters,
        })

        return (
            <>
                {alert?.lastScanCookie && (
                    <OrganizationCookieAlertSummaryReportPage
                        alert={alert}
                        isFetching={isFetching}
                    />
                )}
                {alert?.lastScanRequest && (
                    <OrganizationTrackerAlertSummaryReportPage
                        alert={alert}
                        isFetching={isFetching}
                    />
                )}
            </>
        )
    }
}
