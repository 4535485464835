import React from 'react'
import { Trans } from '@lingui/macro'
import { atob, isBase64Encoded } from '../../../utils/index'
import Avatar from '../../utils/Avatar'
import Tag from '../../utils/Tag'
import Tooltip from '../../utils/Tooltip'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import DateTime from '../../utils/DateTime'
import RequestParamTableRow from '../../utils/RequestParamTableRow'
import AlertSummaryListItem from './AlertSummaryDataList'
import AlertSummaryItem from './AlertSummaryDataList'

export default function AuditAlertSummaryRequestDetails({ scanRequest }) {
    const {
        method,
        audit,
        scan,
        session,
        resourceType,
        rootDomain,
        scanPage,
        initiator,
    } = scanRequest

    const url = new URL(scanRequest.url)
    const { pathname, searchParams } = url
    const decodedSearchParams = Array.from(url.searchParams.entries()).map(
        ([key, value]) => [key, isBase64Encoded(value) ? atob(value) : value]
    )

    return (
        <div className="">
            <div className="flex flex-col gap-2">
                {audit && (
                    <AlertSummaryListItem label={<Trans>Audit</Trans>}>
                        <div className="flex items-center gap-2">
                            <Avatar size="xs" src={audit.imageUrl} />
                            {audit.name}
                        </div>
                    </AlertSummaryListItem>
                )}
                {scan && (
                    <AlertSummaryListItem label={<Trans>Scan</Trans>}>
                        <Tag type="primary">
                            <DateTime dateOnly>{scan.startedAt}</DateTime> -
                            {scan.consentProfile.name}
                        </Tag>
                    </AlertSummaryListItem>
                )}
                {session && (
                    <AlertSummaryListItem
                        isHorizontal
                        label={<Trans>Session</Trans>}
                    >
                        <Tag type="primary">
                            <DateTime dateOnly>{scan.startedAt}</DateTime> -
                            {session.consentProfile.name}
                        </Tag>
                    </AlertSummaryListItem>
                )}
                {scanPage && (
                    <AlertSummaryListItem label={<Trans>Page</Trans>}>
                        {scanPage ? new URL(scanPage?.url).pathname : 'N/A'}
                    </AlertSummaryListItem>
                )}
                {method && (
                    <AlertSummaryListItem label={<Trans>Method</Trans>}>
                        {method}
                    </AlertSummaryListItem>
                )}
                {resourceType && (
                    <AlertSummaryListItem label={<Trans>Type</Trans>}>
                        {resourceType}
                    </AlertSummaryListItem>
                )}
                {initiator && (
                    <AlertSummaryListItem label={<Trans>Initiator Type</Trans>}>
                        <Tag type="primary">{initiator.type}</Tag>
                    </AlertSummaryListItem>
                )}
                {rootDomain && (
                    <AlertSummaryListItem label={<Trans>Domain</Trans>}>
                        {rootDomain}
                    </AlertSummaryListItem>
                )}
                {pathname && (
                    <AlertSummaryListItem
                        isHorizontal
                        label={<Trans>Path</Trans>}
                    >
                        {pathname}
                    </AlertSummaryListItem>
                )}
                {url && (
                    <AlertSummaryListItem
                        isHorizontal
                        label={<Trans>Full URL</Trans>}
                    >
                        {scanRequest.url}
                    </AlertSummaryListItem>
                )}
            </div>

            {decodedSearchParams.length > 0 && (
                <div className="mb-8 w-full flex-shrink-0">
                    <AlertSummaryItem isHorizontal={false}>
                        <div className="mb-2 flex flex-row gap-2">
                            <h3>Request Parameters</h3>
                        </div>
                        <Table hasMinWidth={false} isRounded hasHeader={false}>
                            <TableBody>
                                {decodedSearchParams.map(([key, value]) => (
                                    <RequestParamTableRow
                                        key={key}
                                        paramName={key}
                                        paramValue={value}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </AlertSummaryItem>
                </div>
            )}
        </div>
    )
}
