/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Trans } from '@lingui/macro'
import Icon from '../../utils/Icon'
import Avatar from '../../utils/Avatar'

export default function AuditAlertSummaryRequestChain({
    initiatorScanRequests = [],
    currentRequest = {},
    downstreamScanRequests = [],
}) {
    const reversedData = [...initiatorScanRequests].reverse()

    if (
        initiatorScanRequests.length === 0 &&
        downstreamScanRequests.length === 0
    ) {
        return (
            <div>
                <Trans>No initiator scan requests found!</Trans>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-2">
            {reversedData.map((request, index) => (
                <div
                    key={`test-${index}`}
                    className="mr-2 flex items-center"
                    style={{ marginLeft: `${index * 15}px` }}
                >
                    <Avatar
                        src={request.tracker?.vendor.imageUrl}
                        size="sm"
                        iconName="building-07"
                    />
                    <Icon name="chevron-down" className="mx-2 flex-shrink-0" />
                    <p className="truncate">{request.url || request}</p>
                </div>
            ))}

            <div
                className="mr-2 flex items-center font-bold"
                style={{ marginLeft: `${reversedData.length * 15}px` }}
            >
                <Avatar
                    src={currentRequest.imageUrl}
                    size="sm"
                    iconName="building-07"
                />
                <Icon name="chevron-down" className="mx-2 flex-shrink-0" />
                <p className="w-[900px] truncate">{currentRequest.url}</p>
            </div>

            {downstreamScanRequests.map((request, index) => (
                <div
                    key={`follow-${index}`}
                    className="mr-2 flex items-center"
                    style={{
                        marginLeft: `${(reversedData.length + 4) * 15}px`,
                    }}
                >
                    <Icon name="chevron-down" className="mr-2 flex-shrink-0" />
                    <p className="truncate">{request.url}</p>
                </div>
            ))}
        </div>
    )
}
