import React from 'react'
import { cva } from 'cva'
import Icon from '../../utils/Icon'

const containerVariant = cva('flex gap-4 rounded-lg border p-4', {
    variants: {
        type: {
            success: 'border-green-300',
            warning: 'border-gray-300',
            issue: 'border-yellow-300',
            error: 'border-red-300 bg-red-50',
            critical: 'border-red-300 bg-red-50',
        },
        backgroundColor: {
            success: 'bg-green-50',
            warning: 'bg-gray-50',
            issue: 'border-yellow-300 bg-yellow-50',
            error: 'bg-red-50',
            critical: 'bg-red-50',
        },
    },
})

const iconColor = cva('', {
    variants: {
        type: {
            success: 'green',
            warning: 'gray',
            issue: 'yellow',
            error: 'red',
            critical: 'red',
        },
    },
})

const titleVariant = cva('text-sm font-semibold text-slate-700', {
    variants: {
        type: {
            success: 'text-green-500',
            warning: 'text-gray-700',
            issue: 'text-yellow-700',
            error: 'text-red-500',
            critical: 'text-red-700',
        },
    },
})

export default function AlertSummaryInfoCard({
    iconName,
    type,
    className,
    title,
    text,
    hasBackgroundcolor = true,
    children,
}) {
    return (
        <div
            className={containerVariant({
                type,
                backgroundColor: hasBackgroundcolor ? type : undefined,
                className,
            })}
        >
            <Icon name={iconName} color={iconColor({ type })} size="2xl" />
            <div className="flex max-w-[90%] flex-col gap-2">
                <h1 className={titleVariant({ type })}>{title}</h1>
                {text && <p className="text-sm text-slate-600">{text}</p>}
                {children}
            </div>
        </div>
    )
}
