import React from 'react'
import { Trans } from '@lingui/macro'
import DrawerEmptyCard from '../../utils/DrawerEmptyCard'
import PageContainer from '../../layout/PageContainer'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import AlertSummaryDataCard from './AlertSummaryDataCard'
import AlertSummaryInfoCard from './AlertSummaryInfoCard'
import AlertSummarySection, {
    AlertSummarySectionTitle,
} from './AlertSummarySection'
import { toTitleCase } from '../../../utils/text'
import LoadingIndicator from '../../utils/LoadingIndicator'
import PurposesTags from '../../utils/PurposesTags'
import AuditAlertSummaryCookieDetails from './AuditAlertSummaryCookieDetails'
import AuditAlertSummaryRequestDetails from './AuditAlertSummaryRequestDetails'
import AuditAlertSummaryRequestChain from './AuditAlertSummaryRequestChain'

export default function AuditCookieAlertSummaryReportPage({
    alert,
    isFetching,
}) {
    const { vendor, message, severityType, subjectType, type, lastScanCookie } =
        alert

    const { scanRequest } = lastScanCookie

    if (!alert) {
        return (
            <DrawerEmptyCard
                iconName="check-circle"
                title={<Trans>No alerts</Trans>}
                description={
                    <Trans>No alerts were found during this scan. </Trans>
                }
            />
        )
    }

    if (isFetching) {
        return (
            <PageContainer className="mx-auto max-w-5xl p-8">
                <AuditSummaryHeader />
                <div className="flex h-screen w-full items-center justify-center">
                    <LoadingIndicator />
                </div>
            </PageContainer>
        )
    }

    return (
        <PageContainer className="mx-auto max-w-5xl p-8">
            <AuditSummaryHeader />

            {/* Summary */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Summary</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryInfoCard
                    iconName={subjectType?.toLowerCase()}
                    type={severityType?.toLowerCase()}
                    title={toTitleCase(
                        type?.toLowerCase().split('_').join(' ')
                    )}
                    text={message}
                />
            </AlertSummarySection>

            {/* Domain Section */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Audit</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryDataCard
                    avatarSrc={alert.audit?.imageUrl}
                    avatarIconName="building-07"
                    title={alert?.audit?.name}
                    description={alert.audit?.domains.map(
                        (domain) => domain?.url
                    )}
                />
            </AlertSummarySection>

            {/* Vendor and Subject Section */}
            <AlertSummarySection className="grid grid-cols-2 gap-x-8 gap-y-12">
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Vendor</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={vendor?.name}
                        description="Vendor"
                        avatarSrc={vendor?.imageUrl}
                        avatarIconName="building-07"
                        text={
                            vendor?.description ||
                            'No description found for this vendor'
                        }
                    />
                </div>
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Subject</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={lastScanCookie.name}
                        description="Cookie"
                        avatarSrc={lastScanCookie.imageUrl}
                        avatarIconName="building-07"
                        afterTitle={
                            <PurposesTags
                                showAll
                                purposes={lastScanCookie.purposes || []}
                            />
                        }
                        text={
                            lastScanCookie.description ||
                            'No description found for this cookie'
                        }
                    />
                </div>
            </AlertSummarySection>

            {/* Cookie Instance Example */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Cookie instance example</Trans>
                </AlertSummarySectionTitle>
                <div className="flex flex-col gap-2">
                    {lastScanCookie !== undefined ? (
                        <div className="flex flex-col gap-2">
                            <AuditAlertSummaryCookieDetails
                                scanCookie={lastScanCookie}
                            />
                        </div>
                    ) : (
                        <p>No cookie instance example found</p>
                    )}
                </div>
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert initiator example</Trans>
                </AlertSummarySectionTitle>
                {scanRequest !== undefined ? (
                    <div className="flex flex-col gap-2">
                        <AuditAlertSummaryRequestDetails
                            scanRequest={scanRequest}
                        />
                    </div>
                ) : (
                    <p>No initiator example found</p>
                )}
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert request initiator chain example</Trans>
                </AlertSummarySectionTitle>
                <AuditAlertSummaryRequestChain
                    initiatorScanRequests={scanRequest?.initiatorScanRequests}
                    currentRequest={{
                        url: scanRequest?.url,
                        imageUrl: scanRequest?.tracker?.vendor?.imageUrl,
                    }}
                    downstreamScanRequests={
                        scanRequest?.downstreamScanRequests || []
                    }
                />
            </AlertSummarySection>
        </PageContainer>
    )
}
