import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import PopoverVendorInfo from '../../utils/PopoverVendorInfo'

const AUDIT_ALERT_SUMMARY_QUERY = gql`
    query auditAlertSummaryQuery(
        $groupingId: ID!
        $auditId: ID
        $filters: AlertFilters
    ) {
        alert(groupingId: $groupingId, auditId: $auditId, filters: $filters) {
            id
            groupingId
            type
            severityType
            message
            payload
            subjectName
            subjectType
            audit {
                id
                name
                imageUrl
                maxCookieRetention
                domains {
                    url
                }
            }
            audits {
                id
                type
                name
                isMobile
                isWeb
                scanFrequency
                createdAt
                imageUrl
                tags
                alertsByPeriod {
                    startDate
                    endDate
                    warningAlerts
                    issueAlerts
                    criticalAlerts
                }
                domains {
                    id
                    url
                }
            }
            lastScanRequests {
                id
                method
                resourceType
                url
                rootDomain
                search
                postData
                initiator
                headers
                cookies
                status
                initiatorScanRequests {
                    id
                    url
                    tracker {
                        id
                        vendor {
                            ...PopoverVendorInfo
                        }
                    }
                }
                downstreamScanRequests {
                    id
                    url
                    tracker {
                        id
                        vendor {
                            ...PopoverVendorInfo
                        }
                    }
                }
            }
            lastScanCookie {
                scanRequest {
                    id
                    method
                    audit {
                        id
                        name
                        imageUrl
                    }
                    scan {
                        id
                        startedAt
                        consentProfile {
                            name
                        }
                    }
                    session {
                        id
                        startedAt
                        consentProfile {
                            name
                        }
                    }
                    scanPage {
                        url
                    }
                    initiatorScanRequests {
                        id
                        url
                        tracker {
                            vendor {
                                imageUrl
                            }
                        }
                    }
                    downstreamScanRequests {
                        id
                        url
                        tracker {
                            vendor {
                                imageUrl
                            }
                        }
                    }
                    method
                    resourceType
                    initiator
                    rootDomain
                    tracker {
                        vendor {
                            imageUrl
                        }
                    }
                    url
                }
                audit {
                    imageUrl
                    name
                }
                scan {
                    startedAt
                    consentProfile {
                        name
                    }
                }
                id
                type
                rootDomain
                scanPage {
                    url
                }
                name
                value
                retention
                expiresAt
                request
                blockedReasons
            }
            session {
                id
                startedAt
                consentProfile {
                    id
                    name
                    purposes {
                        id
                        name
                    }
                }
            }
            vendor {
                ...PopoverVendorInfo
            }
            cookie {
                id
                name
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                }
                vendor {
                    id
                    name
                }
            }
            tracker {
                id
                domain
                belongsToOrganization
                purposes {
                    id
                    name
                }
                vendor {
                    id
                    name
                }
            }
        }
    }
    ${PopoverVendorInfo.FRAGMENT}
`

const useAuditAlertSummaryQuery = ({
    groupingId,
    startDate,
    endDate,
    auditId,
}) => {
    const variables = {
        groupingId,
        auditId,
        filters: {
            startDate,
            endDate,
        },
    }

    const {
        data = {},
        error,
        loading: isFetching,
        refetch,
    } = useQuery(AUDIT_ALERT_SUMMARY_QUERY, {
        variables,
        fetchPolicy: 'network-only',
    })

    return {
        error,
        alert: data.alert || {},
        isFetching,
        refetch,
    }
}

export default useAuditAlertSummaryQuery
