import React, { useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'
import useAlertDetailsDrawerQuery from './useAlertDetailsDrawerQuery'
import Tag from '../../utils/Tag'
import DrawerHeader from '../../utils/DrawerHeader'
import DrawerContent from '../../utils/DrawerContent'
import DrawerLoading from '../../utils/DrawerLoading'
import DrawerError from '../../utils/DrawerError'
import Tabs, { Tab } from '../../utils/Tabs'
import GroupedAlertDetailsDrawerTabAlert from './GroupedAlertDetailsDrawerTabAlert'
import PurposesTags from '../../utils/PurposesTags'
import GroupedAlertDetailsDrawerTabObservation from './GroupedAlertDetailsDrawerTabObservation'
import GroupedAlertDetailsDrawerTabRequest from './GroupedAlertDetailsDrawerTabRequest'
import GroupedAlertDetailsDrawerTabActivityWithState from './GroupedAlertDetailsDrawerTabActivityWithState'
import GroupedAlertDetailsDrawerTabCookies from './GroupedAlertDetailsDrawerTabCookies'
import * as constants from '../../../constants'
import Button from '../../utils/Button'

const { APPLICATION_LEVEL_SCAN, APPLICATION_LEVEL_AUDIT } = constants

export default function GroupedAlertDetailsDrawer({
    groupingId,
    startDate,
    selectedPeriod,
    endDate,
    auditId,
    sessionId,
    scanId,
    scanPageId,
    level,
    selectedTab = 'alert',
    drawerState,
    setDrawerState,
}) {
    const TAB_COOKIES = 'cookies'
    const TAB_REQUESTS = 'requests'
    const TAB_OBSERVATIONS = 'observations'

    const history = useHistory()

    const activeTab = drawerState.activeTab || selectedTab
    const setActiveTab = (tab) => setDrawerState({ activeTab: tab })
    const { alert, isFetching, refetch, error } = useAlertDetailsDrawerQuery({
        groupingId,
        startDate,
        endDate,
        auditId,
        scanId,
        sessionId,
        scanPageId,
    })

    useEffect(() => {
        setActiveTab(selectedTab)
    }, [selectedTab])

    if (isFetching) return <DrawerLoading />
    if (error) return <DrawerError error={error} />

    const {
        subjectName,
        subjectType,
        audits,
        message,
        vendor,
        cookie,
        tracker,
        scanPages,
        delegations,
        comments,
        severityType,
    } = alert

    let isWarning = false
    if (severityType === 'WARNING') {
        isWarning = true
    }

    let purposes = []
    if (tracker && Array.isArray(tracker.purposes)) {
        purposes = tracker.purposes.filter((purpose) => purpose !== null)
    }
    if (cookie && Array.isArray(cookie.purposes)) {
        purposes = cookie.purposes.filter((purpose) => purpose !== null)
    }

    return (
        <>
            <DrawerHeader
                avatarIconName="alert-triangle"
                buttons={
                    !isWarning && (
                        <Button
                            type="white"
                            size="sm"
                            iconBeforeProps={{
                                name: 'report',
                                size: 'sm',
                            }}
                            onClick={async () => {
                                const params = new URLSearchParams({
                                    period: selectedPeriod,
                                })
                                if (level === 'ORGANIZATION') {
                                    history.push(
                                        `/report/alert-summary/${
                                            alert.id
                                        }?${params.toString()}`
                                    )
                                }
                                if (level === 'AUDIT') {
                                    history.push(
                                        `/report/alert-summary/${auditId}/${
                                            alert.id
                                        }?${params.toString()}`
                                    )
                                }
                            }}
                        />
                    )
                }
                avatarStatus={subjectType?.toLowerCase() || ''}
                avatarSrc={vendor?.imageUrl}
                title={subjectName}
                description={message}
                afterTitle={
                    purposes ? (
                        <PurposesTags showAll purposes={purposes} />
                    ) : (
                        <Tag>Unclassified</Tag>
                    )
                }
            />
            <Tabs hasMarginBottom={false} className="px-6">
                <Tab
                    isActive={activeTab === 'alert'}
                    onClick={() => setActiveTab('alert')}
                >
                    <Trans>Alert</Trans>
                </Tab>
                {tracker && (
                    <Tab
                        isActive={activeTab === TAB_REQUESTS}
                        onClick={() => setActiveTab(TAB_REQUESTS)}
                    >
                        <Trans>Requests</Trans>
                    </Tab>
                )}
                {subjectType?.toLowerCase() === 'cookie' && (
                    <Tab
                        isActive={activeTab === TAB_COOKIES}
                        onClick={() => setActiveTab(TAB_COOKIES)}
                    >
                        <Trans>Cookies</Trans>
                    </Tab>
                )}

                {level !== APPLICATION_LEVEL_SCAN && (
                    <Tab
                        isActive={activeTab === 'activity'}
                        onClick={() => setActiveTab('activity')}
                    >
                        <Trans>Activity</Trans>
                        <Tag iconNameBefore="message-square">
                            {comments.length}
                        </Tag>
                    </Tab>
                )}
                {level !== APPLICATION_LEVEL_SCAN &&
                    level !== APPLICATION_LEVEL_AUDIT && (
                        <Tab
                            isActive={activeTab === TAB_OBSERVATIONS}
                            onClick={() => setActiveTab(TAB_OBSERVATIONS)}
                        >
                            <Trans>Pages</Trans>
                        </Tab>
                    )}
            </Tabs>
            <DrawerContent>
                {activeTab === 'alert' && (
                    <GroupedAlertDetailsDrawerTabAlert alert={alert} />
                )}
                {activeTab === TAB_OBSERVATIONS && (
                    <GroupedAlertDetailsDrawerTabObservation
                        auditId={alert.auditId}
                        audits={audits}
                        pages={scanPages}
                    />
                )}
                {activeTab === TAB_REQUESTS && (
                    <GroupedAlertDetailsDrawerTabRequest
                        alertGroupingId={groupingId}
                        startDate={startDate}
                        endDate={endDate}
                    />
                )}
                {activeTab === TAB_COOKIES && (
                    <GroupedAlertDetailsDrawerTabCookies
                        alertGroupingId={groupingId}
                        vendorImageUrl={vendor?.imageUrl}
                        startDate={startDate}
                        endDate={endDate}
                    />
                )}
                {activeTab === 'activity' && (
                    <GroupedAlertDetailsDrawerTabActivityWithState
                        isFetching={isFetching}
                        alertGroupingId={groupingId}
                        alertComments={comments}
                        alertDelegations={delegations}
                        onComment={() => refetch()}
                    />
                )}
            </DrawerContent>
        </>
    )
}
