import React from 'react'
import { Trans } from '@lingui/macro'
import PageContainer from '../../layout/PageContainer'
import AuditSummaryHeader from './AuditAlertSummaryHeader'
import AlertSummaryDataCard from './AlertSummaryDataCard'
import AlertSummaryInfoCard from './AlertSummaryInfoCard'
import AlertSummarySection, {
    AlertSummarySectionTitle,
} from './AlertSummarySection'
import { toTitleCase } from '../../../utils/text'
import LoadingIndicator from '../../utils/LoadingIndicator'
import PurposesTags from '../../utils/PurposesTags'
import AuditAlertSummaryRequestDetails from './AuditAlertSummaryRequestDetails'
import AuditAlertSummaryRequestChain from './AuditAlertSummaryRequestChain'

export default function AuditTrackerAlertSummaryReportPage({
    alert,
    isFetching,
}) {
    const scanRequest = alert?.lastScanRequests?.[0]

    if (isFetching) {
        return (
            <PageContainer className="mx-auto max-w-5xl p-8">
                <AuditSummaryHeader />
                <div className="flex h-screen w-full items-center justify-center">
                    <LoadingIndicator />
                </div>
            </PageContainer>
        )
    }

    return (
        <PageContainer className="mx-auto max-w-5xl p-8">
            <AuditSummaryHeader />

            {/* Summary */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Summary</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryInfoCard
                    iconName={alert?.subjectType?.toLowerCase()}
                    type={alert?.severityType?.toLowerCase()}
                    title={toTitleCase(
                        alert?.type?.toLowerCase().split('_').join(' ')
                    )}
                    text={alert?.message}
                />
            </AlertSummarySection>

            {/* Domain Section */}
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Audit</Trans>
                </AlertSummarySectionTitle>
                <AlertSummaryDataCard
                    avatarSrc={alert.audits?.imageUrl}
                    avatarIconName="building-07"
                    title={alert?.audit?.name}
                    description={alert.audit?.domains.map(
                        (domain) => domain?.url
                    )}
                />
            </AlertSummarySection>

            {/* Vendor and Subject Section */}
            <AlertSummarySection className="grid grid-cols-2 gap-x-8 gap-y-12">
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Vendor</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={alert?.vendor?.name}
                        description="Vendor"
                        avatarSrc={alert?.vendor?.imageUrl}
                        avatarIconName="building-07"
                        text={
                            alert?.vendor?.description ||
                            'No description found for this vendor'
                        }
                    />
                </div>
                <div>
                    <AlertSummarySectionTitle>
                        <Trans>Subject</Trans>
                    </AlertSummarySectionTitle>
                    <AlertSummaryDataCard
                        title={alert?.tracker?.domain}
                        description="Tracker"
                        avatarSrc={alert?.vendor?.imageUrl}
                        avatarIconName="building-07"
                        afterTitle={
                            <PurposesTags
                                showAll
                                purposes={alert?.tracker?.purposes || []}
                            />
                        }
                        text={
                            alert?.tracker?.description ||
                            'No description found for this tracker'
                        }
                    />
                </div>
            </AlertSummarySection>
            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert instance example</Trans>
                </AlertSummarySectionTitle>
                {scanRequest !== undefined ? (
                    <div className="flex flex-col gap-2">
                        <AuditAlertSummaryRequestDetails
                            scanRequest={scanRequest}
                        />
                    </div>
                ) : (
                    <p>No initiator example found</p>
                )}
            </AlertSummarySection>

            <AlertSummarySection>
                <AlertSummarySectionTitle>
                    <Trans>Alert request initiator chain example</Trans>
                </AlertSummarySectionTitle>
                <AuditAlertSummaryRequestChain
                    initiatorScanRequests={scanRequest?.initiatorScanRequests}
                    currentRequest={{
                        url: scanRequest?.url,
                        imageUrl: scanRequest?.tracker?.vendor?.imageUrl,
                    }}
                    downstreamScanRequests={
                        scanRequest?.downstreamScanRequests || []
                    }
                />
            </AlertSummarySection>
        </PageContainer>
    )
}
