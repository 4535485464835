import React from 'react'
import { Trans } from '@lingui/macro'
import Table from '../../utils/Table'
import TableBody from '../../utils/TableBody'
import InfiniteScroll from '../../utils/InfiniteScroll'
import AlertsPageTableHeaderRow from './AlertsPageTableHeaderRow'
import AlertsPageTableRow from './AlertsPageTableRow'
import EmptyPageCard from '../../utils/EmptyPageCard'

export default function AlertsPageTable({
    period,
    startDate,
    endDate,
    alerts = [],
    isFetchingMore,
    onFetchMore,
    hasMoreRecords,
    isFetching,
    onAlertStatusChanged,
}) {
    const hasAlerts = isFetching || alerts.length > 0
    return (
        <InfiniteScroll
            isFetchingMore={isFetchingMore}
            onFetchMore={onFetchMore}
            hasMoreRecords={hasMoreRecords && !isFetching}
        >
            {hasAlerts ? (
                <Table isFetching={isFetching} isFullWidth hasMinWidth={false}>
                    <thead>
                        <AlertsPageTableHeaderRow />
                    </thead>
                    <TableBody>
                        {alerts.map((alert) => (
                            <AlertsPageTableRow
                                key={alert.id}
                                alert={alert}
                                period={period}
                                startDate={startDate}
                                endDate={endDate}
                                onAlertStatusChanged={onAlertStatusChanged}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyPageCard
                    iconName="check-circle"
                    title={<Trans>No alerts</Trans>}
                    description={
                        <Trans>No alerts found for the current selection</Trans>
                    }
                />
            )}
        </InfiniteScroll>
    )
}
