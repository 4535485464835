import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function AlertSummarySection({ children, className }) {
    return <div className={twMerge('mt-12', className)}>{children}</div>
}

export function AlertSummarySectionTitle({ children }) {
    return <h2 className="mb-4 text-xs uppercase text-gray-400">{children}</h2>
}
