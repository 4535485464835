import React, { useState } from 'react'
import { Trans } from '@lingui/macro'
import { useHistory, useParams } from 'react-router-dom'
import * as constants from '../../../constants'
import useAppState from '../../hooks/useAppState'
import useNotifications from '../../hooks/useNotifications'
import AlertsPageTableRowActionsStaff from './AlertsPageTableRowActionsStaff'
import useIgnoreAlertMutation from '../../utils/useIgnoreAlertMutation'
import useRestoreAlertMutation from '../../utils/useRestoreAlertMutation'
import UpdateVendorModal from '../../utils/UpdateVendorModal'
import UpdateOrganizationVendorModalWithState from '../classifications-organization-vendors/UpdateOrganizationVendorModalWithState'
import UpdateCookieModalWithState from '../audits-scans-cookies/UpdateCookieModalWithState'
import DeleteCookieModalWithState from '../audits-scans-cookies/DeleteCookieModalWithState'
import UpdateTrackerModalWithState from '../audits-scans-trackers/UpdateTrackerModalWithState'
import DeleteTrackerModalWithState from '../audits-scans-trackers/DeleteTrackerModalWithState'
import CreateOrganizationTrackerModalWithState from '../classifications-organization-trackers/CreateOrganizationTrackerModalWithState'
import UpdateOrganizationTrackerModalWithState from '../classifications-organization-trackers/UpdateOrganizationTrackerModalWithState'
import DeleteOrganizationTrackerModalWithState from '../classifications-organization-trackers/DeleteOrganizationTrackerModalWithState'
import CreateOrganizationCookieModalWithState from '../classifications-organization-cookies/CreateOrganizationCookieModalWithState'
import UpdateOrganizationCookieModalWithState from '../classifications-organization-cookies/UpdateOrganizationCookieModalWithState'
import DeleteOrganizationCookieModalWithState from '../classifications-organization-cookies/DeleteOrganizationCookieModalWithState'

const { USER_ROLE_OWNER: OWNER } = constants

const AlertsPageTableRowActionsWithState = ({
    level,
    alert,
    selectedPeriod,
    onAlertStatusChanged,
}) => {
    const history = useHistory()
    const { id: auditId } = useParams()
    const { currentUser } = useAppState()

    const [updateVendorModalIsOpen, setUpdateVendorModalIsOpen] =
        useState(false)
    const [
        updateOrganizationVendorModalIsOpen,
        setUpdateOrganizationVendorModalIsOpen,
    ] = useState(false)
    const [updateTrackerModalIsOpen, setUpdateTrackerModalIsOpen] =
        useState(false)
    const [deleteTrackerModalIsOpen, setDeleteTrackerModalIsOpen] =
        useState(false)
    const [
        updateOrganizationTrackerModalIsOpen,
        setUpdateOrganizationTrackerModalIsOpen,
    ] = useState(false)
    const [
        deleteOrganizationTrackerModalIsOpen,
        setDeleteOrganizationTrackerModalIsOpen,
    ] = useState(false)
    const [updateCookieModalIsOpen, setUpdateCookieModalIsOpen] =
        useState(false)
    const [deleteCookieModalIsOpen, setDeleteCookieModalIsOpen] =
        useState(false)
    const [
        createOrganizationCookieModalIsOpen,
        setCreateOrganizationCookieModalIsOpen,
    ] = useState(false)
    const [
        createOrganizationTrackerModalIsOpen,
        setCreateOrganizationTrackerModalIsOpen,
    ] = useState(false)
    const [
        updateOrganizationCookieModalIsOpen,
        setUpdateOrganizationCookieModalIsOpen,
    ] = useState(false)
    const [
        deleteOrganizationCookieModalIsOpen,
        setDeleteOrganizationCookieModalIsOpen,
    ] = useState(false)
    const [ignoreAlert] = useIgnoreAlertMutation({
        onCompleted: onAlertStatusChanged,
    })
    const [restoreAlert] = useRestoreAlertMutation({
        onCompleted: onAlertStatusChanged,
    })
    let isIgnored = false
    let isReviewed = false
    if (level === 'ORGANIZATION') {
        isIgnored = alert.status === 'IGNORED'
        isReviewed = alert.status === 'REVIEWED'
    }
    if (level === 'AUDIT') {
        isIgnored = alert.auditStatus === 'IGNORED'
        isReviewed = alert.auditStatus === 'REVIEWED'
    }
    const { dispatchSuccess, dispatchError } = useNotifications()
    const variables = { groupingIds: [alert.id], level }
    if (level === 'AUDIT') {
        variables.auditId = alert.audit.id
    }
    let isWarning = false
    if (alert.severityType === 'WARNING') {
        isWarning = true
    }
    const { lastScanCookies, tracker, cookie, vendor } = alert
    const [scanCookie] = lastScanCookies
    return (
        <>
            {updateVendorModalIsOpen && (
                <UpdateVendorModal
                    vendorId={vendor.id}
                    onDismiss={() => setUpdateVendorModalIsOpen(false)}
                />
            )}
            {updateOrganizationVendorModalIsOpen && (
                <UpdateOrganizationVendorModalWithState
                    title={<Trans>Update vendor for this organization</Trans>}
                    vendorId={vendor.id}
                    onDismiss={() =>
                        setUpdateOrganizationVendorModalIsOpen(false)
                    }
                />
            )}
            {deleteCookieModalIsOpen && (
                <DeleteCookieModalWithState
                    cookie={cookie}
                    onDismiss={() => setDeleteCookieModalIsOpen(false)}
                    // onCompleted={onDeleteCookieCompleted}
                />
            )}
            {updateCookieModalIsOpen && (
                <UpdateCookieModalWithState
                    scanCookie={scanCookie}
                    cookie={cookie}
                    onDismiss={() => setUpdateCookieModalIsOpen(false)}
                    // onCompleted={onUpdateCookieCompleted}
                />
            )}
            {deleteOrganizationCookieModalIsOpen && (
                <DeleteOrganizationCookieModalWithState
                    title={<Trans>Remove your cookie classification</Trans>}
                    cookie={cookie}
                    onDismiss={() =>
                        setDeleteOrganizationCookieModalIsOpen(false)
                    }
                    // onCompleted={onDeleteCookieCompleted}
                />
            )}
            {createOrganizationCookieModalIsOpen && (
                <CreateOrganizationCookieModalWithState
                    title={
                        <Trans>Reclassify cookie for this organization</Trans>
                    }
                    cookie={cookie}
                    scanCookie={scanCookie}
                    onDismiss={() =>
                        setCreateOrganizationCookieModalIsOpen(false)
                    }
                    // onCompleted={onUpdateCookieCompleted}
                />
            )}
            {updateOrganizationCookieModalIsOpen && (
                <UpdateOrganizationCookieModalWithState
                    title={<Trans>Update your cookie classification</Trans>}
                    cookie={cookie}
                    scanCookie={scanCookie}
                    onDismiss={() =>
                        setUpdateOrganizationCookieModalIsOpen(false)
                    }
                    // onCompleted={onUpdateCookieCompleted}
                />
            )}
            {deleteTrackerModalIsOpen && (
                <DeleteTrackerModalWithState
                    tracker={tracker}
                    onDismiss={() => setDeleteTrackerModalIsOpen(false)}
                    // onCompleted={onDeleteTrackerCompleted}
                />
            )}
            {updateTrackerModalIsOpen && (
                <UpdateTrackerModalWithState
                    tracker={tracker}
                    onDismiss={() => setUpdateTrackerModalIsOpen(false)}
                    // onCompleted={onUpdateTrackerCompleted}
                />
            )}
            {deleteOrganizationTrackerModalIsOpen && (
                <DeleteOrganizationTrackerModalWithState
                    title={<Trans>Remove your classification</Trans>}
                    tracker={tracker}
                    onDismiss={() =>
                        setDeleteOrganizationTrackerModalIsOpen(false)
                    }
                    // onCompleted={onDeleteTrackerCompleted}
                />
            )}
            {createOrganizationTrackerModalIsOpen && (
                <CreateOrganizationTrackerModalWithState
                    title={
                        <Trans>Reclassify tracker for this organization</Trans>
                    }
                    tracker={tracker}
                    onDismiss={() =>
                        setCreateOrganizationTrackerModalIsOpen(false)
                    }
                    // onCompleted={onUpdateTrackerCompleted}
                />
            )}
            {updateOrganizationTrackerModalIsOpen && (
                <UpdateOrganizationTrackerModalWithState
                    title={<Trans>Update your classification</Trans>}
                    tracker={tracker}
                    onDismiss={() =>
                        setUpdateOrganizationTrackerModalIsOpen(false)
                    }
                    // onCompleted={onUpdateTrackerCompleted}
                />
            )}

            <AlertsPageTableRowActionsStaff
                vendor={vendor}
                cookie={cookie}
                tracker={tracker}
                isLoading={false}
                isWarning={isWarning}
                canDeleteTracker={tracker !== null}
                canUpdateTracker={tracker !== null}
                canDeleteCookie={cookie !== null}
                canUpdateCookie={cookie !== null}
                isIgnored={isIgnored}
                isReviewed={isReviewed}
                canIgnoreAlert={currentUser.role === OWNER}
                canReviewAlert={currentUser.role === OWNER}
                onExportAlert={async () => {
                    const params = new URLSearchParams({
                        period: selectedPeriod,
                    })
                    if (level === 'ORGANIZATION') {
                        history.push(
                            `/report/alert-summary/${
                                alert.id
                            }?${params.toString()}`
                        )
                    }
                    if (level === 'AUDIT') {
                        history.push(
                            `/report/alert-summary/${auditId}/${
                                alert.id
                            }?${params.toString()}`
                        )
                    }
                }}
                onIgnoreAlert={async () => {
                    try {
                        let message = null
                        if (isIgnored) {
                            await restoreAlert({ variables })
                            message = <Trans>Alert status cleared</Trans>
                        } else {
                            await ignoreAlert({ variables })
                            message = <Trans>Alert ignored</Trans>
                        }
                        dispatchSuccess({ message })
                    } catch (error) {
                        const { message } = error.graphQLErrors[0] || {}
                        dispatchError({ message })
                    }
                }}
                onUpdateTracker={() => setUpdateTrackerModalIsOpen(true)}
                onDeleteTracker={() => setDeleteTrackerModalIsOpen(true)}
                onUpdateOrganizationTracker={() =>
                    setUpdateOrganizationTrackerModalIsOpen(true)
                }
                onDeleteOrganizationTracker={() =>
                    setDeleteOrganizationTrackerModalIsOpen(true)
                }
                onUpdateCookie={() => setUpdateCookieModalIsOpen(true)}
                onDeleteCookie={() => setDeleteCookieModalIsOpen(true)}
                onUpdateOrganizationCookie={() =>
                    setUpdateOrganizationCookieModalIsOpen(true)
                }
                onDeleteOrganizationCookie={() =>
                    setDeleteOrganizationCookieModalIsOpen(true)
                }
                onReclassifyCookieForOrganization={() =>
                    setCreateOrganizationCookieModalIsOpen(true)
                }
                onReclassifyTrackerForOrganization={() =>
                    setCreateOrganizationTrackerModalIsOpen(true)
                }
                onUpdateOrganizationVendor={() =>
                    setUpdateOrganizationVendorModalIsOpen(true)
                }
                onUpdateVendor={() => setUpdateVendorModalIsOpen(true)}
            />
        </>
    )
}

export default AlertsPageTableRowActionsWithState
