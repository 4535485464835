import React from 'react'
import { Trans } from '@lingui/macro'
import HeaderLogo from '../../layout/HeaderLogo'
import PageHeader from '../../layout/PageHeader'

export default function AuditAlertSummaryHeader() {
    return (
        <div className="flex w-full flex-row items-center justify-between border-b border-gray-200 pb-8">
            <PageHeader title={<Trans>Alert Summary Report</Trans>} />
            <div className="flex flex-row items-center gap-2">
                <HeaderLogo />
                <h2 className="text-2xl font-semibold text-slate-900">
                    Webclew
                </h2>
            </div>
        </div>
    )
}
